import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  updateDoc,
  where,
  doc,
  addDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { streamCollection } from "services/firebase";
import { db } from "utils/firebase";

type InitialState = {
  isLoading: boolean;
  error: null | string;
  applications: null | Application[];
  isCreatingApplication: boolean;
  currentUpdatingApplication: null | Application;
  currentViewingApplication: null | Application;
  currentCommentingApplication: null | Application;
  currentScoringApplication: null | Application;

  filterStatus:
    | []
    | {
        lable: string;
        value: string;
      }[];

  filterYear: null | string;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  applications: null,
  isCreatingApplication: false,
  currentUpdatingApplication: null,
  currentViewingApplication: null,
  currentCommentingApplication: null,
  currentScoringApplication: null,
  filterStatus: [],
  filterYear: null,
};

export const ApplicationsSlice = createSlice({
  name: "applications",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setApplications: (state, action: PayloadAction<Application[] | null>) => {
      state.applications = action.payload;
    },
    setCreatingApplication: (state, action: PayloadAction<boolean>) => {
      state.isCreatingApplication = action.payload;
    },
    setCurrentUpdatingApplication: (
      state,
      action: PayloadAction<Application | null>
    ) => {
      state.currentUpdatingApplication = action.payload;
    },
    setCurrentViewingApplication: (
      state,
      action: PayloadAction<Application | null>
    ) => {
      state.currentViewingApplication = action.payload;
    },
    setCurrentCommentingApplication: (
      state,
      action: PayloadAction<Application | null>
    ) => {
      state.currentCommentingApplication = action.payload;
    },
    setFilterStatus: (
      state,
      action: PayloadAction<
        | {
            lable: string;
            value: string;
          }[]
        | []
      >
    ) => {
      state.filterStatus = action.payload;
    },
    setFilterYear: (state, action: PayloadAction<string | null>) => {
      state.filterYear = action.payload;
    },
    setCurrentScoringApplication: (
      state,
      action: PayloadAction<Application | null>
    ) => {
      state.currentScoringApplication = action.payload;
    },
  },
});

export const ApplicationsSelector = (): InitialState =>
  useSelector((state: any) => state.applications);
export const ApplicationsActions = ApplicationsSlice.actions;
export const ApplicationsReducer = ApplicationsSlice.reducer;

export const StreamApplications = async ({
  dispatch,
  role,
  user_id,
  assigned_awards,
  filterYear,
}: {
  dispatch: Dispatch;
  role?: string;
  user_id?: string;
  assigned_awards?: string[];
  filterYear?: string;
}) => {
  dispatch(ApplicationsActions.setLoading(true));
  dispatch(ApplicationsActions.setError(null));

  let queries = [where("active", "==", true), where("year", "==", filterYear)];

  if (role === "candidate") {
    queries = [where("user_id", "==", user_id), where("active", "==", true)];
  }

  if (role === "reviewer") {
    queries = [
      where("product_award", "in", assigned_awards),
      where("status", "==", "in_review"),
      where("active", "==", true),
    ];
  }

  if (role === "judge") {
    queries = [
      where("product_award", "in", assigned_awards),
      // where("status", "==", "shortlisted"),
      where("active", "==", true),
    ];
  }

  try {
    streamCollection({
      collectionName: "applications",
      onGetResult: (result) => {
        if (role == "judge") {
          result = result.filter(
            (app) => app.status == "shortlisted" || app.status == "scored"
          );
        }
        dispatch(ApplicationsActions.setApplications(result));
      },
      onEmptyResult: () => {
        dispatch(ApplicationsActions.setApplications(null));
      },
      queryConstraints: queries,
    });
  } catch (error) {
    dispatch(ApplicationsActions.setError(error.message));
  } finally {
    dispatch(ApplicationsActions.setLoading(false));
  }
};

export const UpdateApplicationStatus = async ({
  dispatch,
  application_id,
  status,
  user_id,
}: {
  dispatch: Dispatch;
  application_id: string;
  status: string;
  user_id?: string;
}): Promise<void> => {
  dispatch(ApplicationsActions.setLoading(true));
  dispatch(ApplicationsActions.setError(null));

  try {
    await updateDoc(doc(db, "applications", application_id), {
      status,
    });

    if (user_id) {
      // Update application statuses
      const statuses = {
        status: status,
        by: user_id,
      };
      await setDoc(
        doc(
          db,
          `applications`,
          application_id,
          `statuses`,
          `${new Date().getTime()}`
        ),
        statuses
      );

      // Update application activities
      const activities = {
        by: user_id,
        context: status,
      };
      await setDoc(
        doc(
          db,
          `applications`,
          application_id,
          `activities`,
          `${new Date().getTime()}`
        ),
        activities
      );
    }

    // dispatch(ApplicationsActions.setCurrentUpdatingApplication(null));
    // dispatch(ApplicationsActions.setCurrentViewingApplication(null));
    // toast.success("Saved");
  } catch (error) {
    dispatch(ApplicationsActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(ApplicationsActions.setLoading(false));
  }
};

export const commentApplication = async ({
  dispatch,
  application,
  comment,
}: {
  dispatch: Dispatch;
  application: Application;
  comment: ApplicationComment;
}): Promise<void> => {
  dispatch(ApplicationsActions.setLoading(true));
  dispatch(ApplicationsActions.setError(null));

  try {
    await addDoc(collection(db, "applications", application.id, "comments"), {
      ...comment,
      created_at: new Date(),
    });
    await updateDoc(doc(db, "applications", application.id), {
      status: "returned",
    });
    await addDoc(collection(db, "applications", application.id, "statuses"), {
      by: comment?.by,
      status: "returned",
    } as Status);
    await addDoc(collection(db, "applications", application.id, "activities"), {
      by: comment?.by,
      context: "update",
      field: "status",
      new_value: "returned",
    } as Activity);
    toast.success("Saved");
  } catch (error) {
    dispatch(ApplicationsActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(ApplicationsActions.setLoading(false));
  }
};

export const scoreApplication = async ({
  dispatch,
  application_id,
  scores,
}: {
  dispatch: Dispatch;
  application_id: string;
  scores: ApplicationScore[];
}): Promise<void> => {
  dispatch(ApplicationsActions.setLoading(true));
  dispatch(ApplicationsActions.setError(null));

  try {
    for (const score of scores) {
      const score_id = `${score?.judge_id}-${score?.year_score_id}`;
      await setDoc(
        doc(db, "applications", application_id, "scores", score_id),
        score
      );
    }
    // toast.success("Saved");
  } catch (error) {
    dispatch(ApplicationsActions.setError(error.message));
    toast.error(error.message);
  } finally {
    dispatch(ApplicationsActions.setLoading(false));
  }
};
