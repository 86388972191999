import React from "react";
import {
  FaFacebookF,
  FaYoutube,
  FaTelegramPlane,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import "styles/footer.css"

export default function Footer() {
  return (
    <footer
      className="et-l et-l--footer"
      //   style={{ marginTop: "60px", height: "420px", backgroundColor: "red" }}
    >
      <div className="et_builder_inner_content et_pb_gutters3">
        <div className="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular">
          <div className="et_pb_row et_pb_row_0_tb_footer">
            <div className="et_pb_column et_pb_column_1_3 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">
              <div className="et_pb_module et_pb_text et_pb_text_0_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                <div className="et_pb_text_inner">
                  <h5>
                    <strong>ADDRESS</strong>
                  </h5>
                  <p>
                    Bridge 2, National Road 6A, Sangkat Prek Leap, Khan Chroy
                    Changva, Phnom Penh
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="et_pb_column et_pb_column_1_3 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">
              <div className="et_pb_with_border et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                <div className="et_pb_text_inner">
                  <h5>
                    <strong>INSTITUTES</strong>
                  </h5>
                  <p>
                    <a
                      href="https://www.idt.edu.kh/"
                      target="_blank"
                      rel="noopener"
                    >
                      Institute of Digital Technology
                    </a>
                    <br />
                    <a
                      href="https://www.idg.edu.kh/"
                      target="_blank"
                      rel="noopener"
                    >
                      Institute of Digital Governance
                    </a>
                    <br />
                    <a
                      href="https://idri.cadt.edu.kh/"
                      target="_blank"
                      rel="noopener"
                    >
                      Institute of Digital Research and Innovation
                    </a>
                    <a href="https://www.cadt.edu.kh/institutes/institute-of-digital-governance/"></a>
                  </p>
                </div>
              </div>
            </div>
            <div className="et_pb_column et_pb_column_1_3 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div className="et_pb_with_border et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                <div className="et_pb_text_inner">
                  <h5>
                    <strong>INFORMATION LINKS</strong>
                  </h5>
                  <p>
                    <a href="https://www.cadt.edu.kh/contact/">Contact Us</a>
                    <br />
                    <a href="https://www.cadt.edu.kh/careers/">Careers</a>
                    <br />
                    <a href="https://www.cadt.edu.kh/academic/admission/">
                      Admission
                    </a>
                    <br />
                    <a href="https://www.cadt.edu.kh/resources/">Resources</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="et_pb_row et_pb_row_1_tb_footer">
            <div className="et_pb_column et_pb_column_4_4 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <ul className="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0_tb_footer clearfix  et_pb_bg_layout_light">
                <li className="et_pb_social_media_follow_network_0_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-facebook">
                  <a
                    href="https://www.facebook.com/CADT.Academy/"
                    title="Follow on Facebook"
                    target="_blank"
                    style={{
                      backgroundColor: "#3b5998",
                      borderRadius: "4px",
                      height: "32px",
                      width: "32px",
                      padding: "5px",
                    }}
                  >
                    <FaFacebookF fontSize={16} color="white" />
                  </a>
                </li>
                <li className="et_pb_social_media_follow_network_1_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-youtube">
                  <a
                    href="https://www.youtube.com/channel/UCgeSEXjZFcKwCfWkxIl69kA"
                    title="Follow on Youtube"
                    target="_blank"
                    style={{
                      backgroundColor: "#a82400",
                      borderRadius: "4px",
                      height: "32px",
                      width: "32px",
                      padding: "5px",
                    }}
                  >
                    {/* <span
                      className="et_pb_social_media_follow_network_name"
                      aria-hidden="true"
                    >
                      Follow
                    </span> */}
                    <FaYoutube fontSize={16} color="white" />
                  </a>
                </li>
                <li className="et_pb_social_media_follow_network_2_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-telegram et-pb-social-fa-icon">
                  <a
                    href="https://t.me/CADT_Official"
                    title="Follow on Telegram"
                    target="_blank"
                    style={{
                      backgroundColor: "#179cde",
                      borderRadius: "4px",
                      height: "32px",
                      width: "32px",
                      padding: "5px",
                    }}
                  >
                    {/* <span
                      className="et_pb_social_media_follow_network_name"
                      aria-hidden="true"
                    >
                      Follow
                    </span> */}
                    <FaTelegramPlane fontSize={16} color="white" />
                  </a>
                </li>
                <li className="et_pb_social_media_follow_network_3_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-linkedin">
                  <a
                    href="https://bit.do/cadt-linkedin"
                    title="Follow on LinkedIn"
                    target="_blank"
                    style={{
                      backgroundColor: "#007bb6",
                      borderRadius: "4px",
                      height: "32px",
                      width: "32px",
                      padding: "5px",
                    }}
                  >
                    {/* <span
                      className="et_pb_social_media_follow_network_name"
                      aria-hidden="true"
                    >
                      Follow
                    </span> */}
                    <FaLinkedinIn fontSize={16} color="white" />
                  </a>
                </li>
                <li className="et_pb_social_media_follow_network_4_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-instagram">
                  <a
                    href="https://www.instagram.com/cadtofficial/"
                    title="Follow on Instagram"
                    target="_blank"
                    style={{
                      backgroundColor: "#ea2c59",
                      borderRadius: "4px",
                      height: "32px",
                      width: "32px",
                      padding: "5px",
                    }}
                  >
                    {/* <span
                      className="et_pb_social_media_follow_network_name"
                      aria-hidden="true"
                    >
                      Follow
                    </span> */}
                    <FaInstagram fontSize={16} color="white" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="et_pb_row et_pb_row_2_tb_footer">
            <div className="et_pb_column et_pb_column_4_4 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div className="et_pb_module et_pb_text et_pb_text_3_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                <div className="et_pb_text_inner">
                  <p>CADT @ 2022 All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
