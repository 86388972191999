import { Stack } from "@mui/material";
import ThemeModeMenu from "components/ThemeModeMenu";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserSelector } from "redux/slices/user";
import Footer from "../Footer";

const GuestGuard = () => {
  const { user } = UserSelector();
  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
};

export default GuestGuard;
