import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Hidden, Paper, Stack } from "@mui/material";
import {
  FAvatar,
  FInput,
  FMultiSelectAutocomplete,
  FSelect,
} from "components/editor/Input";
import { Form, useFormikContext } from "formik";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { PreferencesSelector } from "redux/slices/preferences";
import { UsersSelector } from "redux/slices/users";
import { streamCollection } from "services/firebase";

export default function ApplicationForm() {
  const { isSubmitting, submitForm } = useFormikContext();
  const { values }: any = useFormikContext();
  const { currentViewingUser } = UsersSelector();
  const { awardCategories, userRoles } = PreferencesSelector();

  const AWARD_OPTIONS = awardCategories.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const ROLES_OPTIONS = userRoles.map((role) => ({
    value: role,
    label: capitalize(role),
  }));

  return (
    <Form
      noValidate
      style={{
        width: "100%",
        pointerEvents: currentViewingUser ? "none" : "auto",
      }}
    >
      <Grid container spacing={2}>
        {/* @ts-ignore */}
        <Hidden mdDown>
          <Grid item md={6}>
            <Paper elevation={6}>
              <Stack
                sx={{
                  height: "500px",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  component="img"
                  src="/favicon/logo.png"
                  sx={{ width: "150px", objectFit: "contain" }}
                />
                <Box
                  component="img"
                  src="/static/user.svg"
                  sx={{ width: "250px", objectFit: "contain" }}
                />
              </Stack>
            </Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <FAvatar name="profile" />
            <FInput name="name" />
            {/* <FInput name="password" password type="password" /> */}

            <Stack direction="row" justifyContent="flex-end">
              {!currentViewingUser && (
                <LoadingButton
                  type={"submit"}
                  loading={isSubmitting}
                  variant="contained"
                  startIcon={<Save />}
                >
                  Save
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}
